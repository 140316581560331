import React, { useState, useEffect } from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import { Link } from "../components/utils"
import styled from 'styled-components';
import { motion, useAnimation } from "framer-motion"
import ReactPlayer from "react-player"

import {
  DiscoArticleQuery,
  DiscoArticleQuery_microcmsDiscography_tracks as TrackItem,
  DiscoArticleQuery_microcmsDiscography_credits as CreditItem,
} from "./__generated__/DiscoArticleQuery"
import { fail } from "assert";

export default ({ location, data }: PageProps<DiscoArticleQuery, {}>) => {
  const [scReady, changeScReady] = useState(false,);

  const item = data.microcmsDiscography;

  const Blur = ({ children }) => {
    return (
      <div style={{
        width: '100%',
        height: '100%',
        background: `url(${item.image.url}) no-repeat center`,
        backgroundSize: 'cover',
        position: 'fixed',
        zIndex: 0,
        overflow: 'hidden',
      }}>
        <div style={{
          background: 'inherit',
          filter: 'blur(14px)',
          position: 'absolute',
          top: '-20px',
          left: '-20px',
          right: '-20px',
          bottom: '-20px',
          zIndex: -1,
        }} />
        {children}
      </div>
    )
  }

  const Body = styled.div`
    & *{
      z-index: 1;
    }
  `;

  const Fade = (children, isVidible = true) => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: isVidible ? 1 : 0
        }}>

        {children}
      </motion.div>
    )
  }

  const jacketCtrl = useAnimation()

  const trackItem = (item: TrackItem, num: number) => {
    return (
      <div>
        <h3 className="text-color-default text-xl mb-1">
          {coron(('00' + (num + 1)).slice(-2), item.title)}
        </h3>
        <div className="ml-5 text-color-3">
          {item.origin ? coron('原曲', item.origin) : ''}
          {item.vocal ? coron('Vocal', item.vocal) : ''}
          {item.lyrics ? coron('Lyrics', item.lyrics) : ''}
        </div>
      </div>
    )
  }

  const creditItem = (item: CreditItem,) => {
    return (
      <div className="mb-5">
        <p className="text-color-3 whitespace-pre-wrap break-words max-w-sm">
          {item.part ? `${item.part}` : ''}
        </p>
        <h3 className="text-color-default text-xl m-0 whitespace-pre-wrap break-words max-w-sm">
          {item.name}
        </h3>
        <p>
          {item.website ?
            <Link to={item.website} className='border-b text-color-2'>
              website
              </Link> : ''}
        </p>
      </div>
    )
  }

  const coron = (title, body) => {
    return (
      <div className="flex">
        <div className="whitespace-no-wrap">
          {title}：
        </div>
        <div className="whitespace-pre-wrap break-words max-w-sm">
          {body}
        </div>
      </div>
    )
  }

  const date = new Date(item.date);
  const Year = date.getFullYear().toString();
  const Month = (date.getMonth() + 1).toString();
  const Day = date.getDate().toString();

  return (
    <Layout
      seo={{
        title: item.title,
        description: item.title,
        image: item.image.url
      }}
      location={location}
    >
      {Fade(
        <Blur>
          <div className="bg-black w-full h-full bg-opacity-75">
          </div>
        </Blur>
      )}

      <Body className="my-32 lg:mx-16 mx-4 ">
        <div className="flex">
          <div className="flex-auto table">
            <div className="w-64 h-64 mx-auto ">
              {
                item.soundcloud ?
                  <ReactPlayer
                    url={item.soundcloud}
                    style={{
                      'z-index': '10',
                      position: 'absolute',
                      margin: '0 auto',
                    }}
                    width={'16rem'}
                    height={'16rem'}
                    onReady={() => {
                      jacketCtrl.start({
                        opacity: 0,
                        transition: { duration: 3, delay: 0.3 },
                      })
                    }}
                  >
                  </ReactPlayer>
                  : <span />
              }
              <motion.div animate={jacketCtrl} >
                <img src={item.image.url} className="w-64 h-64 absolute z-10" />
              </motion.div>
            </div>

            <h2 className="text-color-default text-3xl lg:text-5xl m-8 text-center whitespace-pre-wrap">
              {item.title}
            </h2>
            
            <h2 className="text-color-3 text-xl lg:text-3xl m-3 text-center whitespace-pre-wrap">
              {item.head}
            </h2>

            <div className="text-color-3 mt-5 mb-16 text-center whitespace-pre-wrap">
              {item.body}
            </div>

            <div className="md:flex table mx-auto">
              <div className="ml-auto ">
                {coron('仕様', item.type)}
                {coron('頒布日', `${Year}/${Month}/${Day} ${item.release}`)}
                {coron('価格', item.price)}
              </div>
              <div className="md:ml-20 mr-auto flex">
                <div className={`whitespace-no-wrap 
                  ${(item.booth ||
                    item.melonbooks ||
                    item.toranoana ||
                    item.akibahobby ||
                    item.grep
                  ) ? '' : 'hidden'}`}>
                  購入：
                </div>
                <div>
                  <Link to={item.booth} className={item.booth ? '' : 'hidden'}>
                    <p className="border-b mb-2">
                      BOOTH
                    </p>
                  </Link>
                  <Link to={item.melonbooks} className={item.melonbooks ? '' : 'hidden'}>
                    <p className="border-b mb-2">
                      メロンブックス
                    </p>
                  </Link>
                  <Link to={item.toranoana} className={item.toranoana ? '' : 'hidden'}>
                    <p className="border-b mb-2">
                      とらのあな
                    </p>
                  </Link>
                  <Link to={item.akibahobby} className={item.akibahobby ? '' : 'hidden'}>
                    <p className="border-b mb-2">
                      AKIBA-HOBBY
                    </p>
                  </Link>
                  <Link to={item.grep} className={item.grep ? '' : 'hidden'}>
                    <p className="border-b mb-2">
                      グレップ
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="md:flex mt-10 md:ml-16 table mx-auto ">
              <div className="mx-auto mb-16 ">
                <h3 className="text-color-default text-3xl ">
                  Track
                </h3>
                <div className="mx-auto">
                {item.tracks.map(trackItem)}

                </div>
              </div>

              <div className="mx-auto ">
                <h3 className="text-color-default text-3xl">
                  Credit
                </h3>
                {item.credits.map(creditItem)}
              </div>
            </div>

          </div>
        </div>
      </Body>


    </Layout >
  )
}

export const query = graphql`
query DiscoArticleQuery($id: String) {
  microcmsDiscography(identity: {eq: $id}) {
    identity
    title
    head
    body
    image {
      url
    }
    type
    release
    date
    price
    soundcloud
    akibahobby
    booth
    grep
    melonbooks
    toranoana
    tracks {
      lyrics
      origin
      title
      vocal
    }
    credits {
      name
      part
      website
    }
  }
}
`

